<template>
    <div class="row settings_base">
        <div class="col-12">
            <div class="card card-primary" v-if="!iscut">
                <div class="card-header">
                    <h3 class="card-title">基本设置</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">后台名称</label>
                        <input type="text" v-model="formData.title" class="form-control" id="title"
                            placeholder="如 sunny 后台管理系统">
                    </div>
                    <div class="form-group">
                        <label for="sub_title">副标题</label>
                        <textarea class="form-control" rows="2" v-model="formData.sub_title"
                            placeholder="例如，一个不断完善的后台管理系统"></textarea>
                    </div>
                    <h5>logo</h5>
                    <div>
                        <img style="height:90px;width:160px;" :src="formData.logo" id="logo">
                    </div>
                    <div class="form-group">
                        <label for="file">上传logo</label>
                        <div class="input-group">
                            <div class="custom-file">
                                <input @change="upload" type="file" class="custom-file-input" id="file">
                                <label class="custom-file-label" for="file">点击选择图片</label>
                            </div>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                    <button class="btn btn-primary" @click="submit">保存</button>
                </div>
            </div>

            <div v-else>
                <h5>裁切图片</h5>
                <img src="" id="image" alt="" class="mt-2 mb-2">
                <button class="btn btn-primary" @click="cut">确定裁切</button>
            </div>
        </div>

    </div>

</template>

<script>
import { saveBaseSet, getBaseSet } from '@/requests/api'
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
export default {
    name: 'settings_base',
    mounted() {
        getBaseSet().then((res) => {
            res.forEach((item) => {
                if (item.name == 'title') this.formData.title = item.value
                if (item.name == 'sub_title') this.formData.sub_title = item.value
                if (item.name == 'logo') this.formData.logo = this.$config.url + '/storage/' + item.value
            })
        })
    },
    data() {
        return {
            iscut: false,
            formData: {
                title: '',
                sub_title: '',
                logo: '',
            },
            cropper: null,
            hascut: false,
        }
    },
    methods: {
        cut() {
            this.iscut = false;
            let img = this.cropper.getCroppedCanvas().toDataURL('image/jpeg')
            this.cropper.destroy();
            this.formData.logo = img
            this.hascut = true
        },
        upload(e) {
            this.iscut = true;
            var that = this
            let file = e.target.files[0]
            let reader = new FileReader();
            reader.onloadend = function () {
                let image = document.getElementById('image');
                image.src = reader.result
                that.cropper = new Cropper(image, {
                    aspectRatio: 16 / 9,
                    dragMode: 'move'
                });
            }
            reader.readAsDataURL(file);
        },
        submit() {
            saveBaseSet(this.formData).then(() => {
                this.$XModal.message({
                    status: 'success',
                    content: '操作成功'
                })
            })
        }
    }
}
</script>

<style>

</style>